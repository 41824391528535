.authentication-process {
    background-color: #060606;
    display: flex;
    flex-direction: column;
    font-family: Outfit, sans-serif;
    justify-content: start;
    padding: 64px 0px;
}

@media (max-width: 991px) {
    .authentication-process {
        padding: 20px 20px;
    }
}

.section-title {
    color: #fefefe;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -1.44px;
}

@media (max-width: 991px) {
    .section-title {
        max-width: 100%;
        font-size: 40px;
    }
}

.section-description {
    color: #6c757d;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.6px;
    margin-top: 32px;
}

@media (max-width: 991px) {
    .section-description {
        max-width: 100%;
    }
}

.process-step {
    border-radius: 24px;
    background-color: #101214;
    display: flex;
    margin-top: 32px;
    width: 100%;
    gap: 40px 64px;
    overflow: hidden;
    justify-content: start;
    flex-wrap: wrap;
    padding: 32px;
}

@media (max-width: 991px) {
    .process-step {
        max-width: 100%;
        padding: 16px 16px;
    }
}

.step-content {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    flex-basis: 0%;
}

@media (max-width: 991px) {
    .step-content {
        max-width: 100%;
    }
}

.step-title {
    color: #fefefe;
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -1.44px;
}

@media (max-width: 991px) {
    .step-title {
        max-width: 100%;
        font-size: 40px;
        line-height: 49px;
    }
}

.step-description {
    color: #6c757d;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.6px;
    margin-top: 16px;
}

@media (max-width: 991px) {
    .step-description {
        max-width: 100%;
    }
}

.step-image {
    aspect-ratio: 1.33;
    object-fit: cover;
    object-position: center;
    width: 400px;
    border-radius: 16px;
    min-width: 240px;
    margin: auto 0;
}

.team-section {
    border-radius: 16px;
    display: flex;
    margin-top: 32px;
    width: 100%;
    padding-top: 24px;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    .team-section {
        max-width: 100%;
    }
}

.team-title {
    color: #fefefe;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -1.44px;
}

@media (max-width: 991px) {
    .team-title {
        max-width: 100%;
        font-size: 40px;
    }
}

.team-members {
    overflow-x: auto;
    display: flex;
    margin-top: 32px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .team-members {
        max-width: 100%;
    }
}

.member-list {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: start;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .member-list {
        max-width: 100%;
    }
}

.member-card {
    border-radius: 24px;
    background-color: #101214;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    overflow: hidden;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
    padding: 24px;
}

@media (max-width: 991px) {
    .member-card {
        padding: 16px 16px;
    }
}

.member-image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100px;
    border-radius: 100px;
    max-width: 100%;
    object-fit: cover;
}

.member-info {
    display: flex;
    margin-top: 16px;
    width: 100%;
    padding-top: 8px;
    flex-direction: column;
    justify-content: center;
}

.member-name {
    color: #fefefe;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.84px;
}

.member-title {
    color: #6661ff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 3.08px;
    text-transform: uppercase;
    margin-top: 12px;
}

.member-bio {
    color: #6c757d;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.54px;
    margin-top: 12px;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.guarantee-section {
    background-color: #4b45ff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 40px;
    margin-top: 64px;
}
.guarantee-icon {
    width: 56px;
    height: 56px;
    object-fit: contain;
}
.guarantee-content {
    flex: 1;
}
.guarantee-title {
    color: #fefefe;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.96px;
    margin-bottom: 16px;
}
.guarantee-description {
    color: #fefefe;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.6px;
}
@media (max-width: 991px) {
    .guarantee-section {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-top: 40px;
        padding: 20px;
    }
}

.feature-card {
    background-color: #191c1f;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    flex: 1;
    min-width: calc(33.333% - 16px);
}
.feature-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}
.feature-title {
    color: #fefefe;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.54px;
    margin-top: 16px;
}
@media (max-width: 991px) {
    .feature-card {
        min-width: calc(50% - 16px);
    }
}

.unique-value-section {
    font-family: Outfit, sans-serif;
    padding: 64px 0px;
    margin: 0 auto;
    max-width: 1200px;
}
.content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.section-title {
    color: #fefefe;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -1.44px;
    margin-bottom: 24px;
}
.section-description,
.section-conclusion {
    color: #fefefe;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.6px;
    margin-bottom: 24px;
}
.feature-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
}
@media (max-width: 991px) {
    .unique-value-section {
        padding: 40px 20px;
    }
    .section-title {
        font-size: 40px;
    }
}
