.App {
    text-align: center;
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    max-width: 1200px ;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    position: relative;
}

.second-section::before,
.second-section::after {
    content: '';
    position: absolute;
    top: 0;
    width: 120px; /* Adjust the width for the fade effect */
    height: 100%;
    z-index: 22;
}
.second-section::before {
    left: 0;
    background: linear-gradient(to right, rgb(0, 0, 0), rgba(255, 255, 255, 0));
}

.second-section::after {
    right:0;
    background: linear-gradient(to left, rgb(0, 0, 0), rgba(255, 255, 255, 0));
}
.black-ellipse {
    width: 100%;
height: 536.008px;
flex-shrink: 0;
    border-radius: var(--Spacing-spacing-none, 765.142px);
background: radial-gradient(50% 50% at 50% 50%, #000 0%, #000 32.5%, rgba(0, 0, 0, 0.00) 100%);
}
.container {
    position: relative;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-height: 900px;
    min-height: 900px;
}
.radius-card {
        width:100%;
        height: 768.417px;
        flex-shrink: 0;
        border-radius: 1268.417px;
        background: radial-gradient(50% 50% at 50% 50%, #14123F 0%, rgba(20, 18, 63, 0.00) 100%);
        position: absolute; /* Position the circle absolutely */
        top: 150%; /* Center it vertically */
        left: 50%; /* Center it horizontally */
        bottom: -100%;
        transform: translate(-50%, -50%); /* Offset to truly center */
        z-index: 1; /* Place it behind all other elements */
    }
.fade-in {
    animation: fadeInUp 1s forwards;
}
.fade-in-quick {
    animation: fadeInUp 0.3s forwards;
}
.fade-in-logo {
    animation: fadeInUp 2s forwards;
}
.floating-image {
    opacity: 0;
    position: absolute;
    animation: fadeInUp 1s forwards, float 3s infinite ease-in-out;
}

.sneaker {
    animation: fadeInUp 1s forwards, float 3s infinite ease-in-out;
}

.phone-image {
    animation: fadeInUp 2s forwards, shrink 1s 1s forwards;
    top: -30px
}
.title {
    animation: titleFade 1s forwards;
    bottom: 20px;
}
.title-quick {
    animation: titleFade 1.5s forwards;
    bottom: 20px;
}


@keyframes titleFade {
    0% {
        opacity: 0;
        transform: translateY(50px);
        transform: scale(1.3);

    }
    100% {
        opacity: 1;
        transform: translateY(0);
        transform: scale(0.9);

    }
}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes shrink {
    0% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(0.9);
    }
}

.recent-trades { 
    top: -110px;
    position: relative;
}
.header-text {
    text-align: center;
font-feature-settings: 'liga' off;
text-shadow: 0px 51.146px 51.146px rgba(0, 0, 0, 0.50);
font-family: Outfit;
font-size: 100px;
font-style: normal;
font-weight: 700;
line-height: 90%; 
letter-spacing: -3px;
background: linear-gradient(272deg, #1FD7FF 0%, #8A86FF 47.92%, #FFF 99.83%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
z-index: 10;
position: relative;

}
@media only screen and (max-width: 768px) {
    .header-text {
        font-size: 48px;
        margin-left: 20px;
        margin-right: 20px;
    }
}
.subheader-text {
    color: var(--Text-text-primary-invert, #FEFEFE);
text-align: center;
/* Text/Heading/H6 */
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 22px */
letter-spacing: -0.6px;
z-index: 10;
}
.hidden {
    visibility: hidden;
}

/* Specific positioning for each sneaker around the phone */
.floating-image:nth-child(1) { top: 10%; left: 10%; }
.floating-image:nth-child(2) { top: 10%; right: 10%; }
.floating-image:nth-child(3) { top: 50%; left: 5%; transform: translateY(-50%) rotate(20deg); }
.floating-image:nth-child(4) { top: 50%; right: 5%; transform: translateY(-50%) rotate(-20deg); }
.floating-image:nth-child(5) { bottom: 10%; left: 10%; }
.floating-image:nth-child(6) { bottom: 10%; right: 10%; }
.floating-image:nth-child(7) { bottom: 30%; left: 20%; transform: rotate(10deg); }
.floating-image:nth-child(8) { bottom: 30%; right: 20%; transform: rotate(-10deg); }
/* Phone positioning */
.floating-image:nth-child(9) { transform: translate(-50%, -50%); }
.floating-image:nth-child(10) { transform: translate(-50%, -50%); }


/* CAROUSEL SECTION */
.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    z-index: 11;
}

.carousel-track {
    display: flex;
    width: calc(200%);
    animation: scroll 20s linear infinite;
    z-index: 11;
}

.carousel-card {
    flex: 0 0 auto;
    width: 25%; 
    padding: 10px;
    z-index: 11;

}

.carousel-card img {
    z-index: 11;
    width: 100%;
}
    
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 768px) {
    .floating-image:nth-child(1) { top: 20%; left: 10%; }
    .floating-image:nth-child(2) { top: 10%; right: 10%; }
    .floating-image:nth-child(3) { top: 50%; left: 5%; transform: translateY(-50%) rotate(20deg); }
    .floating-image:nth-child(4) { top: 50%; right: 5%; transform: translateY(-50%) rotate(-20deg); }
    .floating-image:nth-child(5) { bottom: 80%; left: 10%; }
    .floating-image:nth-child(6) { bottom: 70%; right: 10%; }
    .floating-image:nth-child(7) { bottom: 50%; left: 20%; transform: rotate(10deg); }
}
