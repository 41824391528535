/* styles.css */

/* Styles for UserProfileCard component */
.user-profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    width: 200px;
  }
  
  .profile-picture img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #ccc;
  }
  
  /* Styles for UserGrid component */
  .user-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    width: 100%
  }
  
